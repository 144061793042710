<template>
  <div>
    <h1>Galleri</h1>
    <div>
      <model-collada
          :rotation="rotation"
          @on-load="onLoad"
          :lights="lights"
          src="figure_edited_rotated_50k_face.dae"
      />
    </div>
  </div>
</template>

<script>

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {
  ModelCollada,
} from 'vue-3d-model'

export default {

  name: "Gallery",
  components: {
    ModelCollada
  },

  data() {
    return {
      rotation: {
        x: 0,
        y: 0,
        z: 0
      },
      lights: [
        {
          type: 'HemisphereLight',
          position: {x: 0, y: 1, z: 0},
          skyColor: 0xaaaaff,
          groundColor: 0x806060,
          intensity: 0.6,
        },
        {
          type: 'HemisphereLight',
          position: {x: 0, y: 1, z: 1},
          skyColor: 0xaaaaff,
          groundColor: 0x806060,
          intensity: 0.6,
        },
        {
          type: 'DirectionalLight',
          position: {x: 1, y: 1, z: 1},
          color: 0xffffff,
          intensity: 0.8,
        },
      ]
    }
  },

  methods: {
    onLoad() {
      this.rotate();
    },
    rotate() {
      this.rotation.y += 0.01;
      requestAnimationFrame(this.rotate);
    }
  }
}
</script>


<style scoped>

</style>